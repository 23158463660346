import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {graphql} from 'gatsby'
import Page from "../components/page"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from '../constants/Theme'
import { makeStyles } from "@material-ui/core"
import { createStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => createStyles({
  root: {
    "& + .html__insert": {
      paddingBottom: 64,
      paddingTop: 64,
      [theme.breakpoints.down("md")]: {
        maxWidth: 680,
          paddingTop: 32,
          paddingBottom: 32
      },
    }
  }
}))

const NotFoundPage = ({data: {datoCmsSetting: {page404}}}) => {
  const [reRendered, rerenderComponent] = React.useState(false);
  const classes = useStyles();
  useEffect(() => {
    if(!reRendered){
      rerenderComponent(true);
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <Layout page={page404}>
        <span className={classes.root} ></span>
        <SEO title="404: Not found" />
        <Page widgets={page404.widgets} />
      </Layout>
    </ThemeProvider>
  )
}

export const query = graphql`
  query NotFoundPageQuery {
    datoCmsSetting {
      page404 {
        ...Page
      }
    }
  }
`

export default NotFoundPage
